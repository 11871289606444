$(document).ready(function() {


    if($(window).width() < 768) {}{
        $('.apartments__left').on('click', function(e){
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".apartments__right").offset().top
            }, 2000);
        });
    }


    // gsap.to(".block-page-header__sygnet", {
    //     scrollTrigger: {
    //       scrub: true
    //     },
    //     y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
    //     ease: "none"
    // });

    $('.go-top').on('click', function(e) {
        e.preventDefault();
        if($('.home').length) {
            $.fn.fullpage.moveTo(1);
        } else {
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        }
    });


    if($('.home').length){
        $('#js-hero-logo').trigger('play');
        setTimeout(function(){
            $('.hero__go-next').addClass('active');
        }, 1500);

        var myFullpage = new fullpage('#fullpage', {
            licenseKey: 'gplv3-license',
            fadingEffect: true,
            lockAnchors: false,
            anchors:['firstPage', 'secondPage'],
            onLeave: function(index, nextIndex, direction) {
                if (nextIndex == 1) {
                    $('#js-hero-logo').get(0).currentTime = 0;
                    $('#js-hero-logo').get(0).play();
                }
                if (nextIndex == 2) {
                    $('#js-mid-section').get(0).play();
                    $('#js-mid-section').get(0).currentTime = 0;
                    $('#js-mid-section').get(0).playbackRate = 2;
                    setTimeout(function() {
                        console.log('sygnet 1 play');
                        $('#js-sygnet').get(0).currentTime = 0;
                        $('#js-sygnet').addClass('active');
                        $('#js-sygnet').get(0).play();
                    }, 2400);
                } else {
                    $('#js-sygnet').removeClass('active');
                }
                if (nextIndex == 3) {
                    setTimeout(function() {
                        console.log('sygnet 2 play');
                        $('#js-sygnet-2').addClass('active');
                        $('#js-sygnet-2').get(0).currentTime = 0;
                        $('#js-sygnet-2').get(0).play();
                    }, 3000);
                } else {
                    $('#js-sygnet-2').removeClass('active');
                }
            }
        });

        $('.hero__go-next').on('click', function(e) {
            e.preventDefault();
            $.fn.fullpage.moveSectionDown();
        });

        $('.go-top').on('click', function(e) {
            e.preventDefault();
            $.fn.fullpage.moveTo(1);
        });

        $('.block-menu__link').on('mouseenter', function() {
            let image = $(this).data('image');
            $('.block-menu__image[data-image="'+ image +'"]').addClass('block-menu__image--active');
            $('.block-menu__menu').addClass('hover');
        });

        // Handle 'mouseleave' event
        $('.block-menu__link').on('mouseleave', function() {
            let image = $(this).data('image');
            $('.block-menu__image[data-image="'+ image +'"]').removeClass('block-menu__image--active');
            $('.block-menu__menu').removeClass('hover');
        });
    } else {
        $('#fullpage').fullpage({
            licenseKey: 'gplv3-license',
            lockAnchors: false,
            anchors:['firstPage', 'secondPage'],
            fadingEffect: true,
        });
    }



});